<template>
  <div>
    <div v-if="!showVersion" class="yingyong">
      <el-card v-for="item in data" :key="item.id" class="box-card">
        <div>{{ item.name }}</div>

        <div class="btn">
          <div @click="toVersion(item)">客户端版本</div>
          <div style="margin-left: 10px">
            <router-link :to="'/application/one?id=' + item.id"
              >设置</router-link
            >
          </div>
        </div>
      </el-card>
    </div>

    <version
      v-if="showVersion"
      ref="versionPage"
      @versionBack="versionBack"
    ></version>
  </div>
</template>
<script>
import { list } from "../../../api/management.js";
import version from "./version/index";
export default {
  components: {
    version,
  },
  data() {
    return {
      data: "",
      showVersion: false,
      applicationId: "",
    };
  },
  mounted() {
    list().then((res) => {
      this.data = res.data.list;
    });
  },
  methods: {
    toVersion(item) {
      this.showVersion = true;
      this.applicationId = item.id;
      this.$nextTick(() => {
        this.$refs.versionPage.init(item.id);
      });
    },
    versionBack(val) {
      this.showVersion = val;
    },
  },
};
</script>

<style scoped>
.yingyong {
  padding: 24px;
}
.yingyong .el-card {
  width: 300px;
  height: 150px;
  text-align: center;
  line-height: 150px;
  float: left;
  margin-right: 20px;
  border-radius: 10px;
}
::v-deep .el-card__body {
  padding: 0;
  position: relative;
}
a {
  display: inline-block;
  color: #000;
}
.btn {
  position: absolute;
  display: flex;
  top: 50px;
  right: 20px;
  color: #3476d6;
  font-size: 13px;
}
.btn div {
  cursor: pointer;
}
.btn a {
  color: #3476d6;
}
</style>
