import request from './request.js';
import public_request from './public_request'
//获取应用列表
export function list() {
    return request({
        url: '/application-management/applications',
        method: 'get',
        params: public_request({

        })
    })
}

export function addVersionNum(data) {
    return request({
        url: '/application-management/resource-version',
        method: 'post',
        data: public_request(data)
    })
}
//获取客户端版本列表

export function verisonList(data) {
    return request({
        url: '/application-management/versions',
        method: 'get',
        params: public_request(data)
    })
}

//新增客户端版本

export function addVerisonList(data) {
    return request({
        url: '/application-management/version',
        method: 'post',
        data: public_request(data)
    })
}

//删除客户端版本

export function deleteVerisonList(data) {
    return request({
        url: '/application-management/version',
        method: 'DELETE',
        data: public_request(data)
    })
}

//修改客户端版本
export function editVerisonList(data) {
    return request({
        url: '/application-management/version',
        method: 'put',
        data: public_request(data)
    })
}