<template>
  <div class="version-page">
    <el-page-header
      content="版本"
      @back="goBack"
    />
    <el-card class="table-card">
      <el-drawer
        :title="ruleForm.id ? '编辑版本' : '添加版本'"
        :visible.sync="showAddOrEdit"
        :direction="direction"
        :size="drawerSize"
        :before-close="resetForm"
      >
        <div class="drawer-content">
          <el-form
            ref="versionRef"
            :model="ruleForm"
            :rules="rules"
            label-width="100px"
            class="demo-ruleForm"
            label-position="top"
          >
            <el-form-item
              label="设备类型"
              prop="deviceType"
            >
              <el-select
                v-model="ruleForm.deviceType"
                placeholder="请选择设备类型"
                style="width:100%"
              >
                <el-option
                  v-for="item in deviceTypeList"
                  :key="item.number"
                  :label="item.cnVal"
                  :value="item.number"
                />
              </el-select>
            </el-form-item>

            <el-form-item
              label="版本号"
              prop="version"
            >
              <el-input
                v-model="ruleForm.version"
                show-word-limit
              />
            </el-form-item>

            <el-form-item
              label="升级类型"
              prop="updateType"
            >
              <el-select
                v-model="ruleForm.updateType"
                placeholder="请选择升级状态"
                style="width:100%"
              >
                <el-option
                  v-for="item in updateTypeList"
                  :key="item.number"
                  :label="item.cnVal"
                  :value="item.number"
                />
              </el-select>
            </el-form-item>

            <el-form-item
              label="最小支持版本"
              prop="minVersion"
            >
              <el-input
                v-model="ruleForm.minVersion"
                show-word-limit
              />
            </el-form-item>

            <el-form-item
              label="接口版本"
              prop="apiVersion"
            >
              <el-input
                v-model="ruleForm.apiVersion"
                show-word-limit
              />
            </el-form-item>

            <el-form-item
              label="资源版本"
              prop="resourceVersion"
            >
              <el-select
                v-model="ruleForm.resourceVersion"
                placeholder="请选择资源版本"
                style="width:100%"
              >
                <el-option
                  v-for="item in resourceVersionList"
                  :key="item"
                  :label="item"
                  :value="item"
                />
              </el-select>
              <!-- <el-input
                v-model="ruleForm.resourceVersion"
                show-word-limit
              ></el-input> -->
            </el-form-item>

            <el-form-item
              label="发布状态"
              prop="releaseStatus"
            >
              <el-select
                v-model="ruleForm.releaseStatus"
                placeholder="请选择发布状态"
                style="width:100%"
              >
                <el-option
                  v-for="item in versionStatusList"
                  :key="item.number"
                  :label="item.cnVal"
                  :value="item.number"
                />
                <!-- <el-option label="已发布" value="1"></el-option>
                <el-option label="未发布" value="2"></el-option>
                <el-option label="暂不选择" value="3"></el-option> -->
              </el-select>
            </el-form-item>

            <el-form-item
              label="忽略型号"
              prop="ignoreModel"
            >
              <el-input
                v-model="ruleForm.ignoreModel"
                show-word-limit
              />
            </el-form-item>

            <el-form-item
              label="升级内容"
              prop="description"
            >
              <el-input
                v-model="ruleForm.description"
                type="textarea"
                :rows="5"
                maxlength="255"
                show-word-limit
              />
            </el-form-item>

            <el-form-item
              label="APK下载地址"
              prop="apkUrl"
            >
              <el-input
                v-model="ruleForm.apkUrl"
                show-word-limit
              />
            </el-form-item>

            <el-form-item
              label="APK包大小（kb）"
              prop="apkSize"
            >
              <el-input
                v-model="ruleForm.apkSize"
                show-word-limit
              />
            </el-form-item>

            <el-form-item
              label="APK包MD5码"
              prop="apkMD5"
            >
              <el-input
                v-model="ruleForm.apkMD5"
                show-word-limit
              />
            </el-form-item>
          </el-form>

          <div class="drawer-footer">
            <el-button
              type="primary"
              :loading="addLoading"
              @click="submitForm"
            >
              保存
            </el-button>
            <el-button @click="resetForm">
              取消
            </el-button>
          </div>
        </div>
      </el-drawer>
      <div style="margin-bottom: 10px">
        <el-button
          type="primary"
          @click="showAddOrEdit = true"
        >
          添加版本
        </el-button>
      </div>
      <el-table
        v-tableHeight="{bottomOffset: 100}"
        height="100px"
        :data="tableData"
        :header-cell-style="styleObj"
        border
        style="width: 100%"
        :row-style="{height: '0'}"
        :cell-style="{padding: '0'}"
      >
        <el-table-column
          width="50"
          type="index"
          label="序号"
        />
        <el-table-column
          prop="version"
          label="版本号"
        />
        <el-table-column
          prop="deviceTypeName"
          label="设备类型"
        />
        <el-table-column
          prop="updateTypeName"
          label="升级类型"
        />
        <el-table-column
          prop="minVersion"
          label="最小支持版本"
        />
        <el-table-column
          prop="apiVersion"
          label="接口版本"
        />
        <el-table-column
          prop="resourceVersion"
          label="资源版本"
        />
        <el-table-column
          prop="ignoreModel"
          label="忽略型号"
        />
        <el-table-column
          prop="description"
          label="升级内容"
          maxlength="255"
        />
        <el-table-column
          prop="releaseStatusName"
          label="发布状态"
        />
        <el-table-column
          prop="apkUrl"
          label="APK下载地址"
        />
        <el-table-column
          prop="apkSize"
          label="APK包大小"
        />
        <el-table-column
          prop="apkMD5"
          label="APK包MD5码"
        />
        <el-table-column
          prop="operating"
          min-width="100"
          label="操作"
        >
          <template slot-scope="scope">
            <el-button
              type="text"
              size="medium"
              @click="edit(scope.row)"
            >
              编辑
            </el-button>
            <el-popconfirm
              :title="'确认删除 ' + scope.row.version + '吗？'"
              @confirm="deleteVersion(scope.row)"
            >
              <el-button
                slot="reference"
                type="text"
                size="medium"
                style="color: red; margin-left: 10px"
              >
                删除
              </el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>

      <!--列表分页 -->
      <div
        class="block"
        style="margin-top: 20px; text-align: right"
      >
        <el-pagination
          :current-page="page"
          :page-size="size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="len"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
    </el-card>
  </div>
</template>

<script>
import {
  verisonList,
  addVerisonList,
  deleteVerisonList,
  editVerisonList,
} from "@/api/management";

import {resourceVersion} from '@/api/public';
export default {
  data() {
    return {
      deviceTypeList: [],
      updateTypeList: [],
      versionStatusList: [],
      resourceVersionList:[],
      applicationId: "",
      ruleForm: {
        deviceType: "",
        version: "",
        updateType: "",
        minVersion: "",
        apiVersion: "",
        ignoreModel: "",
        releaseStatus: "",
        resourceVersion: "",
        description: "",
        apkUrl: "",
        apkSize: "",
        apkMD5: "",
      },
      rules: {
        deviceType: [
          { required: true, message: "请选择设备类型", trigger: "change" },
        ],
        version: [
          //  { type: 'number', message: '设备类型必须为数字值', trigger: 'blur' },
          { required: true, message: "请输入版本号", trigger: "blur" },
          { validator: this.formValidation.versionNum, trigger: "blur" },
        ],
        updateType: [
          {
            required: true,
            message: "请选择升级类型",
            trigger: "change",
          },
        ],
        minVersion: [
          {
            required: true,
            message: "请输入最小支持版本",
            trigger: "blur",
          },
          { validator: this.formValidation.versionNum, trigger: "blur" },
        ],
        apiVersion: [
          { required: true, message: "请输入接口版本", trigger: "blur" },
          { validator: this.formValidation.checkapiVersion, trigger: "blur" },
        ],
        resourceVersion: [
          { required: true, message: "请输入资源版本", trigger: "change" },
        ],
        releaseStatus: [
          {
            required: true,
            message: "请选择发布状态",
            trigger: "change",
          },
        ],
        description: [
          { required: false, message: "请填写升级内容", trigger: "blur" },
        ],
        ignoreModel: [{
            min: 0,
            max: 45,
            message: "字符长度超出范围0-45",
            trigger: "blur",
          },],
        description: [
          { message: "", trigger: "blur" },
          {
            min: 0,
            max: 255,
            message: "长度在 0 到 255 个字符",
            trigger: "blur",
          },
        ],
        apkUrl: [
          { required: false, message: "请输入下载地址", trigger: "blur" },
          {
            validator: this.formValidation.checkapkUrl,
            trigger: "blur",
          },
        ],
        apkSize: [
          { required: false, message: "请输入apk包大小", trigger: "blur" },
          {
            validator: this.formValidation.checkapkSize,
            trigger: "blur",
          },
        ],
        apkMD5: [
          {
            min: 32,
            max: 32,
            message: "apkMD5长度为32个字符",
            trigger: "blur",
          },
        ],
      },
      tableData: [],
      styleObj: {
        background: "#f5f7fa",
      }, 
      page: 1,
      size: 10,
      len: 0,
      showAddOrEdit: false,
      direction: "rtl",
      drawerSize: "683px",
      addLoading: false,
    };
  },
  created() {
    this.deviceTypeList = this.common.allValueNeed("device-type");
    this.updateTypeList = this.common.allValueNeed("app-client-update-type");
    this.versionStatusList = this.common.allValueNeed(
      "app-client-version-status"
    );


    
  },
  mounted() {},

  methods: {
    findName(arr, id) {
      let name = "";
      arr.forEach((item) => {
        if (item.number == id) {
          name = item.cnVal;
        }
      });
      return name;
    },
    //请求客户端列表数据
    init(id) {
      this.applicationId = id;
      this.getVerisonList();
      resourceVersion({applicationId:this.applicationId}).then((res)=>{
        console.log(res);
        this.resourceVersionList = res.data.list
        
        
    })
    },
    //用应用id获取列表数据
    getVerisonList() {
      let search = {
        applicationId: this.applicationId,
        pageNo: this.page,
        pageSize: this.size,
      };
      verisonList(search).then((res) => {
        if (res.code == 0) {
          this.tableData = res.data.list;
          this.tableData.forEach((item) => {
            item.deviceTypeName = this.findName(
              this.deviceTypeList,
              item.deviceType
            );
            item.updateTypeName = this.findName(
              this.updateTypeList,
              item.updateType
            );
            item.releaseStatusName = this.findName(
              this.versionStatusList,
              item.releaseStatus
            );
          });
          console.log(this.tableData);
         console.log(res.data)
          this.len = res.data.total;
        }
      });
    },
    //新增客户端版本
    submitForm() {
      this.$refs.versionRef.validate((valid) => {
        if (valid) {
          if (this.ruleForm.id) {
            // 编辑接口
            this.ruleForm.versionId = this.ruleForm.id;
            editVerisonList(this.ruleForm).then((res) => {
              if (res.code == 0) {
                this.$message.success("修改版本成功!");
                // this.showAddOrEdit = false;
                this.resetForm()
                this.getVerisonList();
              }
            });
          } else {
            this.ruleForm.applicationId = this.applicationId;
            addVerisonList(this.ruleForm).then((res) => {
              if (res.code == 0) {
                this.$message.success("添加版本成功！");
                // this.showAddOrEdit = false;
                this.resetForm()
                this.getVerisonList();
              } else {
                this.$message.error(res.message);
              }
            });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    goBack() {
      this.$emit("versionBack", false);
    },

    //分页
    handleSizeChange(val) {
      this.size = val;
      this.getVerisonList();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getVerisonList();
    },
    edit(row) {
      console.log(row);
      this.showAddOrEdit = true;
      this.$nextTick(() => {
        this.ruleForm = JSON.parse(JSON.stringify(row));
      });
    },
    // 删除
    deleteVersion(row) {
      deleteVerisonList({ versionId: row.id }).then((res) => {
        if (res.code == 0) {
          this.$message.success("删除成功");
          this.getVerisonList();
        } else {
          this.$message.success(res.message);
        }
      });
    },
    resetForm() {
      this.ruleForm.id = "";
      this.showAddOrEdit = false;
      console.log(this.ruleForm);
      this.$nextTick(() => {
        this.$refs.versionRef.resetFields();
      });
    },
  },
};
</script>

<style lang="less" scoped>
.version-page {
  padding: 20px;
  height: 100%;
  .el-page-header {
    margin-bottom: 20px;
  }
  .table-card {
    height: calc(100vh - 150px);
    padding: 20px;
  }
  .drawer-content {
    display: flex;
    flex-direction: column;
    padding: 20px;
    height: 100%;
    .el-form {
      flex: 1;
      height: 0;
      overflow: auto;
    }
    .drawer-footer {
      display: flex;
      justify-content: center;
      // .el-button {
      //   // flex: 1;
      // }
    }
  }
}
/deep/ .el-form-item {
  margin-bottom: 18px;
}
/deep/ .el-form-item__label {
  line-height: 0;
}
/deep/ .el-drawer:focus {
  outline: none;
}
/deep/ .el-drawer__header {
  margin: 0;
  padding: 0;
  margin: 20px 20px 0px 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #ccc;
  // padding: 0;
  span {
    outline: none;
    font-weight: 600;
    color: #000;
  }
}
/deep/ .el-drawer__body {
  overflow-y: scroll;
}
</style>
